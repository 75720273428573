//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { Overlay } from './../classes/overlay.js'
//-----------------------------------------------------------------------------------------------------------------


// Fonction gérant le menu mobile
export function overlayMenu() {
  const overlayMenu   = new Overlay('overlayMenu', '.btnMenu', 'showOverlayMenu', '#overlay-menu .closeOverlay', true, false)
  const overlaySearch = new Overlay('overlayMenu', 'html:not(.showOverlayMenu) .btnSearch', 'showOverlayMenu', '#overlay-menu .closeOverlay', true, false, false)

  $('.btnSearch').on('click', function() {
    setTimeout( function() { $('#search').focus() }, 100)
  })


  /*$('.btnMenu').on('click', function() {
    $('.searchWrapper').animate({opacity: 'hide'}, 300, function() {
      $('.linksWrapper').animate({opacity: 'show'}, 300)
    })
  })

  $('.btnSearch').on('click', function() {
    $('.linksWrapper').animate({opacity: 'hide'}, 300, function() {
      $('.searchWrapper').animate({opacity: 'show'}, 300)
    })
  })*/
}


// Fonction gérant l'overlay de partage
export function overlayShare() {
  const overlayShare = new Overlay('overlayShare','.btnShare', 'showOverlayShare', '#overlay-share .closeOverlay', true, false)
  
  if ( typeof __sharethis__ != 'undefined' ) {
    __sharethis__.init(__sharethis__.config)
  }
}
