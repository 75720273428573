//-----------------\\
//--- Les views ---\\
//-----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'
import $ from 'jquery'

import {
  View, Home, Template, Publications, Search, Contact, JobOffer
} from './global.js'
//-----------------------------------------------------------------------------------------------------------------


export function setView() {

  // Page standart __________
  Barba.BaseView.extend({
    namespace: 'sdtView',
    onEnter:          function() {              },
    onEnterCompleted: function() { View.init()  },
    onLeave:          function() { View.leave() },
    onLeaveCompleted: function() {              }
  }).init()


  // Page d'accueil __________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnter:          function() {},
    onEnterCompleted: function() { Home.initHome()  },
    onLeave:          function() { Home.leaveHome() },
    onLeaveCompleted: function() {}
  }).init()


  // Page de publications __________
  Barba.BaseView.extend({
    namespace: 'publicationsView',
    onEnter:          function() {},
    onEnterCompleted: function() { Publications.initPublications()  },
    onLeave:          function() { Publications.leavePublications() },
    onLeaveCompleted: function() {}
  }).init()


  // Page de recherche __________
  Barba.BaseView.extend({
    namespace: 'searchView',
    onEnter:          function() {},
    onEnterCompleted: function() { Search.initSearch()  },
    onLeave:          function() { Search.leaveSearch() },
    onLeaveCompleted: function() {}
  }).init()


  // Page de base __________
  Barba.BaseView.extend({
    namespace: 'templateView',
    onEnter:          function() {},
    onEnterCompleted: function() { Template.initTemplate()  },
    onLeave:          function() { Template.leaveTemplate() },
    onLeaveCompleted: function() {}
  }).init()


  // Page de contact __________
  Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter:          function() {},
    onEnterCompleted: function() { Contact.initContact()  },
    onLeave:          function() { Contact.leaveContact() },
    onLeaveCompleted: function() {}
  }).init()

  // Page de contact __________
  Barba.BaseView.extend({
    namespace: 'jobOfferView',
    onEnter:          function() {},
    onEnterCompleted: function() { JobOffer.initJobOffer()  },
    onLeave:          function() { JobOffer.leaveJobOffer() },
    onLeaveCompleted: function() {}
  }).init()

}
