//---------------------------\\
//---- Classe Scrollfire ----\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Classe permet d'ajouter la valeur fire au data-scrollfire lorsque celui-ci est visible dans le viewport
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { isMobile } from '../functions/helper.js'
//-----------------------------------------------------------------------------------------------------------------

export class Scrollfire {
  constructor(offset = 100, mobile = false) {
    this.tElements = [].slice.call(document.querySelectorAll('[data-scrollfire=""]'))
    this.tReferences = []
    this.offset = offset
      
    if(mobile) {
      this.init()
    } else {
      if(isMobile()) {
        this.tElements.forEach((e) => { e.setAttribute('data-scrollfire', 'fire') })
      } else {
        this.init()
      }
    }
  }


  // Initialise les événements sur les scrollfire et stock les fonctions dans un Array en terme de référence
  // pour pouvoir supprimer les événements plus tard
  init() {
    var self = this

    this.tElements.forEach((e, index) => {
      this.tReferences.push({ e, reference() { self.onVisibilityChange(e, index) }})
    })

    this.tReferences.forEach((e) => {
      e.reference()
      this.addScrollEvent(e.reference)
      addEventListener('DOMContentLoaded', e.reference, false)
      addEventListener('load', e.reference, false)
      addEventListener('resize', e.reference, false)
    })
  }


  // Permet d'actualiser le Array des scrollfire non déclencher
  update() {
    this.destroy()
    
    var self = this
    this.tElements = [].slice.call(document.querySelectorAll('[data-scrollfire=""]'))

    this.tElements.forEach((e, index) => {
      this.tReferences.push({ e, reference() { self.onVisibilityChange(e, index) }})
    })

    this.tReferences.forEach((e) => {
      this.addScrollEvent(e.reference)
      addEventListener('DOMContentLoaded', e.reference, false)
      addEventListener('load', e.reference, false)
      addEventListener('resize', e.reference, false)
    })
  }


  // Permet de détruire tout ce que la classe à initialisé
  destroy() {
    this.tReferences.forEach((e) => {
      this.removeScrollEvent(e.reference)
      removeEventListener('DOMContentLoaded', e.reference, false)
      removeEventListener('load', e.reference, false)
      removeEventListener('resize', e.reference, false)
    })
    this.tElements   = []
    this.tReferences = []
  }


  // Retourne la condition indiquand si l'élément est visible dans le viewport ou pas
  isElementInViewport(e) {
    var rect = e.getBoundingClientRect()
    var offset = ( e.getAttribute('data-offset') ? e.dataset.offset : this.offset)

    if($(window).width() <= 768) offset = 0

    return (
      rect.bottom >= 0 &&
      rect.right  >= 0 &&
      rect.top    <= (window.innerHeight - offset || document.documentElement.clientHeight - offset) &&
      rect.left   <= (window.innerWidth  || document.documentElement.clientWidth)
    )
  }
  

  // Détect si l'élément est visible dans le viewport
  onVisibilityChange(e, index) {
    var old_visible = false
    var visible     = this.isElementInViewport(e)

    if(e.hasAttribute('data-now'))
      e.setAttribute('data-scrollfire', 'fire')

    if (visible != old_visible) {
      old_visible = visible
      e.setAttribute('data-scrollfire', 'fire')
      this.removeScrollEvent(this.tReferences[index].reference)
      removeEventListener('DOMContentLoaded', this.tReferences[index].reference, false)
      removeEventListener('load', this.tReferences[index].reference, false)
      removeEventListener('resize', this.tReferences[index].reference, false)
    }
  }

  // Utilisé pour ajouter un événement de scroll sur le plugin smooth scrollbar ou bien le scroll natif
  addScrollEvent(myFunction) {
    $(window).on('scroll', myFunction)
  }

  // Utilisé pour retirer un événement de scroll sur le plugin smooth scrollbar ou bien le scroll natif
  removeScrollEvent(myFunction) {
    $(window).off('scroll', myFunction)
  }
}
