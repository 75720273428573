//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// Création du slick de la bannière
export function slickbanner() {
  $('#slickBanner').slick({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    draggable: true,
    pauseOnHover: false,
    fade: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  })
}


// Création du slick des publications
export function slickPublication() {
  $('#slickPublication').slick({
    prevArrow: $('.slickPublicationNav .prev'),
    nextArrow: $('.slickPublicationNav .next'),
    variableWidth: true,
  })
}


// Création du slick des boutons de l'accueil
export function slickButtons() {
  $('#slickButtons').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      { breakpoint: 600,  settings: { slidesToShow: 1 }},
    ]
  })
}

// Création du slick des témoignages
export function slickTemoignages() {
  $('.slickTestimonials').slick({
    asNavFor: '.slickPeople',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    responsive: [
      { breakpoint: 768,  settings: { fade: false, dots: true }},
    ]
  })

  $('.slickPeople').slick({
    prevArrow: $('.slickPeopleNav .prev'),
    nextArrow: $('.slickPeopleNav .next'),
    asNavFor: '.slickTestimonials',
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    focusOnSelect: true,
    responsive: [
      { breakpoint: 1700, settings: { slidesToShow: 2 }},
      { breakpoint: 1400, settings: { slidesToShow: 1 }},
    ]
  })
}


// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 1000 : delai

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function() {
    $(carousel).slick('unslick')
  }, delai)
}
