(function($) {

  $.fn.BLK_scrollfire = function(options) {

    // VARIABLES __________
    var settings = $.extend({
      animationClass: 'fire', //--> Le nom de la classe ajoutée pour déclencher l'animation
      mobile: false,          //--> Détermine s'il y a des transitions sur mobile
      offset: 0,            //--> Le offset au bas de l'écran pour que l'animation se déclenche
      mediaquery: [
        { breakpoint: 1024, settings: { offset: 50 }},
        { breakpoint: 768,  settings: { offset: 0  }}
      ]
    }, options)
    var offset, element, multiScrollfire, scrollfire
    var elements = $('[data-scrollfire], [data-multiScrollfire]')
    var scrollPosition = $(document).scrollTop()
    var windowWidth = $(window).width()
    var windowHeight = $(window).height()
    getOffset()


    // FONCTIONS __________
    $.fn.BLK_scrollfire.init = function() {
      elements = $('[data-scrollfire], [data-multiScrollfire]')

      if (settings.mobile) {
        scrollFire()
      } else {
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          scrollFire()
        } else {
          $('[data-scrollfire]').attr('data-scrollfire', 'fire')
          $('[data-multiScrollfire]').attr('data-multiScrollfire', 'fire')
        }
      }
      return this
    }

    $.fn.BLK_scrollfire.destroy = function() {
      $(document).off('scroll')
      $(window).off('resize')
      return this
    }

    function getOffset() {
      for (var i = 0; i < settings.mediaquery.length; i++) {
        if (windowWidth > settings.mediaquery[settings.mediaquery.length - 1].breakpoint) {
          if (windowWidth < settings.mediaquery[i].breakpoint && windowWidth > settings.mediaquery[i + 1].breakpoint) {
            offset = settings.mediaquery[i].settings.offset
          } else if (windowWidth > settings.mediaquery[0].breakpoint) {
            offset = settings.offset
          }
        } else {
          offset = settings.mediaquery[settings.mediaquery.length - 1].settings.offset
        }
      }
    }

    // Gestion de l'ajout de l'ajout de la classe d'animation
    function scrollFire() {
      // Laisser le temps au animation d'apparaître
      scrollPosition = $(document).scrollTop()

      for(var i = 0; i < elements.length; i++) {
        element = $(elements[i])
        scrollfire = element.attr('data-scrollfire')
        multiScrollfire = element.attr('data-multiScrollfire')

        // Se déclenche automatiquement si l'attribut now est sur l'élément
        if (element.attr('data-now') == '') element.attr('data-scrollfire', 'fire')

        // Si la position du scroll est plus petite que celle de l'élément, si c'est une animation multiple, s'il a la class d'animation
        // Retire la classe d'animation pour éventuellement la remettre si l'utilisateur défile en bas à nouveau
        if (scrollPosition + windowHeight <= element.offset().top + offset) {
          if (typeof multiScrollfire !== typeof undefined && multiScrollfire !== false && multiScrollfire == 'fire') {
            element.attr('data-multiScrollfire', '')
          }
        }

        // Si la position du scroll est plus grande que celle de l'élément et si elle n'a pas la classe d'animation, on ajoute cette classe
        if (scrollPosition + windowHeight >= element.offset().top + offset) {
          if (typeof scrollfire !== typeof undefined && scrollfire !== false && scrollfire != 'fire') {
            element.attr('data-scrollfire', 'fire')
          } else {
            element.attr('data-multiScrollfire', 'fire')
          }
        }
      }
    }



    // EXÉCUTION __________
    // Si c'est permis en mobile ou pas
    if (settings.mobile || (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !settings.mobile)) {
      // Gestion du offset grâce aux dimensions de l'écran
      $(window).on('resize', function() {

        // Actualisation de la variable
        windowWidth  = $(window).width()
        windowHeight = $(window).height()

        getOffset()
      })

      // Gestion de l'ajout de l'ajout de la classe d'animation au scroll
      $(document).on('scroll', function() {
        scrollFire()
      })
    }

    return this
  }

}(jQuery))
