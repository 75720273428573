//-------------------------------\\
//--- Importation des scripts ---\\
//-------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main.js importe tous les fichiers qu'on a de besoin, ça commence par les node-modules, puis les fichiers
// propres au projet.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Importation des nodes modules __________
import $ from 'jquery'
window.jQuery = $
import UI from './classes/UI.js'
window.UI = UI
import 'jquery.easing'
import 'jquery-validation'
import 'slick-carousel'
import 'jquery-mask-plugin'
import 'chosen-js'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js'
import '../styles/style.scss'


// Importation des scripts __________
import './plugins/smoothScroll.js'
import './plugins/scrollfire.js'
import './plugins/parallax.js'
import './transition.js'
import './global.js'
import 'bootstrap-datepicker'

import { loadAnimation } from './functions/functions.js'
UI.add('loadAnimation', loadAnimation)


// Importation pour les scripts un peu plus bas __________
import { Loader } from './classes/loader.js'
import { initPageTransition } from './transition.js'


// Démarrage des scripts __________
$(document).ready(function() {
  initPageTransition()
  var firstloader = new Loader()
  firstloader.reset()
  firstloader.setCompleteCallback([function() {
    setTimeout(function() {
      $('html').removeClass('chargement')
      //$.fn.BLK_scrollfire.init()
    }, 600)
  }])
  firstloader.start()
  $(document).scrollTop(0)
  //$.fn.BLK_smoothScroll()

  $('body').keydown( function(e) {
    var keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey  && keycode == 13) || (e.metaKey  && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
})
