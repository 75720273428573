//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { magicSelect, formContact, textareaHeight, fixInput, formNewsletter, formJobOffer, multipleInputAdd, calendarInput } from './functions/forms.js'
import { overlayMenu, overlayShare } from './functions/overlay.js'
import { noSamePageDestination, shareUrl, tiroirs, removeHoverOnMobile, parallax, newsFilter, shareModal, searchButton, isIE, coordsHash } from './functions/functions.js'
import { slickPublication, slickTemoignages, slickButtons, slickbanner, destroySlick } from './functions/slicks.js'
import { Parallax } from './classes/Parallax.js'
import { Scrollfire } from './classes/Scrollfire.js'
//-----------------------------------------------------------------------------------------------------------------

window.shareUrl = shareUrl

// Initialisation sur toutes les pages
export class View {
  static init() {
    fixInput()
    searchButton()
    overlayMenu()
    overlayShare()
    formNewsletter()
    //$.fn.BLK_scrollfire()
    window.parallax = new Parallax()
    window.scrollfire = new Scrollfire()
    noSamePageDestination()
    removeHoverOnMobile()
    isIE()
  }

  static leave() {
    window.parallax.destroy()
  }
}


// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome() {
    this.init()
    slickbanner()
    slickButtons()
    slickPublication()
    slickTemoignages()
    //parallax()
  }

  static leaveHome() {
    this.leave()
    destroySlick($('#slickPublication, #slickButtons, .slickTestimonials, .slickPeople'))
  }
}


// Classe initialisant les scripts présents dans la page de publications
export class Publications extends View {
  static initPublications() {
    this.init()
    magicSelect()
    newsFilter()
  }

  static leavePublications() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de recherche
export class Search extends View {
  static initSearch() {
    this.init()
  }

  static leaveSearch() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de base
export class Template extends View {
  static initTemplate() {
    this.init()
    tiroirs()
    formContact()
    magicSelect()
    //shareModal()
  }

  static leaveTemplate() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de contact
export class Contact extends View {
  static initContact() {
    this.init()
    formContact()
    magicSelect()
    textareaHeight()
    coordsHash()
  }

  static leaveContact() {
    this.leave()
  }
}

// Classe initialisant les scripts présents dans la page offre d'emploi
export class JobOffer extends View {
  static initJobOffer() {
    this.init()
    formJobOffer()
    textareaHeight()
    calendarInput()
    multipleInputAdd('#form-career', '.add-button-task', '.js-task-list', '.js-task-listItem', '.js-add-task-template', '.js-task-delete')
    multipleInputAdd('#form-career', '.add-button-quality', '.js-quality-list', '.js-quality-listItem', '.js-add-quality-template', '.js-quality-delete')
    multipleInputAdd('#form-career', '.add-button-requirement', '.js-requirement-list', '.js-requirement-listItem', '.js-add-requirement-template', '.js-requirement-delete')
  }

  static leaveJobOffer() {
    this.leave()
  }
}
