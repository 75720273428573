//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { isMobile } from './helper'
import 'eonasdan-bootstrap-datetimepicker'
//-----------------------------------------------------------------------------------------------------------------


// LES FONCTIONS
// Transforme les selects
export function magicSelect() {
  $('.chosen-select').chosen({ disable_search: true })
}


// Fonction qui gère le label des input
export function fixInput() {
  // Si le focus est sur le champ, on enlève la classe vide pour que le label se déplace
  $('input').focusin(function() {
    $(this).removeClass('notEmpty')
  })

  // Si le champ est vide, on replace le label à sa position d'origine au focusout
  $('input').focusout(function() {
    ($(this).val() != '' ? $(this).addClass('notEmpty') : $(this).removeClass('notEmpty'))
  })
}


// Fonction permettant de modifier la hauteur d'un textarea par rapport à son contenu
export function textareaHeight() {
  $('body').on('change keydown keyup keydown paste cut', 'textarea', function() {
    $(this).height(0).height(this.scrollHeight)
  }).find('textarea').change()
}



// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf, docx, doc or rtf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}



// LES RÈGLES __________
// Règle du email
export function validationEmail() {
  jQuery.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}



// LES FORMATS __________
// Format du téléphone
export function formatPhone(element) {
  $(element).mask('(000) 000-0000')
}

// Format du code postal
export function formatCodePostal(element) {
  $(element).mask('ABA BAB', {'translation': {
    A: {pattern: /[A-Za-z]/},
    B: {pattern: /[0-9]/}
  }})
}

// Format de la date
export function formatDate(element) {
  $(element).mask('00/00/0000')
}



// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (element.attr('type') == 'radio') {
        error.insertBefore(element.closest('.radiobox-field').find('.errorblock'))
      } else if (element.attr('type') == 'checkbox') {
        error.insertBefore(element.closest('.checkbox-field').find('.errorblock'))
      } else if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    },
    submitHandler: function() {
      if (typeof handler != 'undefined') {
        eval(handler + '()')
        return false
      }
      return true
    }
  })
  $(submit).click(function() {
    $(form).submit()
  })
}



// LES VALIDATIONS __________
// Validation du formulaire de contact
export function formContact() {
  validationEmail()
  formatPhone('#contactPhone')
  var m = getMessages()
  var rules = {
    firstName: { required: true },
    lastName:  { required: true },
    email:     { required: function (){ return ($('#contactPhone').val() == '') }, courriel: m.email },
    phone:     { required: function (){ return ($('#contactEmail').val() == '') }, minlength: 14 },
    message:   { required: true },
  }
  var messages = {
    firstName: { required: m.required },
    lastName:  { required: m.required },
    email:     { required: m.required, courriel: m.email  },
    phone:     { required: m.required, minlength: m.phone },
    message:   { required: m.required },
  }

  formValidation('#formContact', '#submitContact', rules, messages, 'handleContact')
}

// Validation du formulaire de l'infolettre
export function formNewsletter() {
  validationEmail()
  formatPhone('#contactPhone')
  var m = getMessages()
  var rules =    { email: { required: true,       courriel: true    }}
  var messages = { email: { required: m.required, courriel: m.email }}

  formValidation('#formInfolettre', '#submitInfolettre', rules, messages)
}



// LES HANDLER __________
// Handle le formulaire de contact pour l'envoi
function handleContact() {
  $.request('courrielator::onFormSubmit', {
    loading: $.oc.stripeLoadIndicator,
    data: {
      firstName: $('#contactFirstName').val(),
      lastName:  $('#contactLastName' ).val(),
      email:     $('#contactEmail'    ).val(),
      phone:     $('#contactPhone'    ).val(),
      service:   $('#contactService'  ).val(),
      message:   $('#contactMessage'  ).val(),
    },
    complete: function (output) {
      var response = output.responseJSON
    }
  })
}


// LES VALIDATIONS __________
// Validation du formulaire d'emploi
export function formJobOffer() {
  validationEmail()
  formatPhone('#careerPhone')
  formatDate('#form-example-date')
  formatDate('#form-example-date-end')
  formatCodePostal('#careerZip')
  var m = getMessages()
  var rules = {
    employer: { required: true },
    address: { required: true },
    town: { required: true },
    zip: { required: true },
    phone: { required: true, minlength: 14 },
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    title: { required: true },
    date: { required: true },
    'date-end': { required: true },
    'business-desc': { required: true },
    'responsability[]': { required: true },
    'quality[]': { required: true },
    'requirement[]': { required: true },
    scolarity: { required: true },
    schedule: { required: true },
    salary: { required: true },
    'apply-method': { required: true },
  }
  var messages = {
    employer: { required: m.required },
    address: { required: m.required },
    town: { required: m.required },
    zip: { required: m.required },
    phone: { required: m.required, minlength: m.phone },
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email },
    title: { required: m.required },
    date: { required: m.required },
    'date-end': { required: m.required },
    'business-desc': { required: m.required },
    'responsability[]': { required: m.required },
    'quality[]': { required: m.required },
    'requirement[]': { required: m.required },
    scolarity: { required: m.required },
    schedule: { required: m.required },
    salary: { required: m.required },
    'apply-method': { required: m.required },
  }

  formValidation('#formCareer', '#submitCareer', rules, messages)
}

export function multipleInputAdd(form, addButton, list, paramlistItem, paramTemplate, deleteButton) {
  $(addButton).on('click', function(e) {
    const formCareer = $(form)
    const containerList = e.currentTarget.parentNode.parentNode.parentNode.querySelector(list)
    const listItem = e.currentTarget.parentNode.parentNode.parentNode.querySelectorAll(paramlistItem)
    const template = e.currentTarget.parentNode.parentNode.parentNode.querySelector(paramTemplate).content.cloneNode(true)
    const nbFieldItem = listItem[0].querySelectorAll('input').length
    const noNewListItem = listItem.length + 1

    for (var cpt=0; cpt<nbFieldItem; cpt++){
      var idFieldGeneric = listItem[0].querySelectorAll('input')[cpt].id.slice(0, -1)

      // Modifier le contenu du template selon le numéro de l'item
      template.querySelector('input[id^=' + idFieldGeneric + ']').setAttribute('id', idFieldGeneric + noNewListItem)
      template.querySelector('label[for^=' + idFieldGeneric + ']').setAttribute('for', idFieldGeneric + noNewListItem)
    }

    containerList.appendChild(template)

    // Animation
    const newListItem = $(paramlistItem)
    const newItem = newListItem.last()
    const heigthNewItem = `${newItem[0].clientHeight}px`
    newItem.css({'height': '0'})
    $(newItem[0]).animate({height: heigthNewItem, opacity: '1'}, 400)

    $(deleteButton).click(function (e){
      $(e.currentTarget.parentNode.parentNode).animate({height: 'hide', opacity: '0'}, 400, function() {
        $(this).remove()
      })
    })
  })
}

// Initialisation du calendrier
export function calendarInput() {

  // Si on est pas en mobile, mettre le calendrier en JS
  if (!isMobile()){

    $.fn.datepicker.dates['fr'] = {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
      monthsShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'juin', 'jui', 'août', 'sep', 'oct', 'nov', 'déc'],
      today: 'Aujourd\'hui',
      clear: 'Clear',
      format: 'dd/mm/yyyy',
      titleFormat: 'MM yyyy',
      weekStart: 0
    }

    $('.datepickerWrapper').datepicker({
      language: 'fr',
      format: 'dd MM yyyy',
      todayHighlight: true,
      startDate: $('.datepickerWrapper').data('startDate')
    })

    $('.input-calendar input').on('focus', function() {
      $(this).parent().find('.datepickerWrapper').addClass('show')
    })

    $('.datepickerWrapper').on('changeDate', function(e) {
      $(this).prev().prev().val($(this).datepicker('getFormattedDate', 'dd/mm/yyyy'))
      $('.datepickerWrapper').removeClass('show')
      e.currentTarget.parentNode.querySelector('input').classList.add('valid')
    })

    $('.input-calendar').click(function (e) {
      e.stopPropagation()
    })
    $(document).click(function () {
      let x, i
      x = document.querySelectorAll('.datepickerWrapper')
      for (i = 0; i < x.length; i++) {
        x[i].classList.remove('show')
      }
    })
  }
}