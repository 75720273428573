//---------------------------\\
//--- Fonctions générales ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import Barba from 'barba.js'
//-----------------------------------------------------------------------------------------------------------------

// Retourne une condition de si c'est un mobile
export function isMobile() {
  var isMobile = false

  if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
  || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
    isMobile = true
  }

  return isMobile
}

export function shareUrl() {
  var link = window.location.href
  copyTextToClipboard(link)
  alert('Un url a été copié sur votre presse-papiers.')
  return false
}

// Empêche une transition si la destination est la page actuelle
export function noSamePageDestination() {
  var links = document.querySelectorAll('a[href]')
  var cbk = function(e) {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', cbk)
  }
}


// Fonction initialisant les tiroirs
export function tiroirs() {
  $('.tiroirs > li .toggleTiroir').on('click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.tiroirs li.open .tiroir').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $('.tiroirs li.open').removeClass('open')
    if (!ouvert) {
      var chosen = this
      setTimeout(function() { $(chosen).parent().addClass('open') }, 0)
      $('.tiroir', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    }
    return false
  })
}


// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
export function scrollToBlock (depart, destination, duration, ajustement) {
  // Paramètres optionnels
  duration   = (typeof duration   === 'undefined') ? 600 : duration
  ajustement = (typeof ajustement === 'undefined') ? 0   : ajustement

  // Défilement au clique
  $(depart).on( 'click', function() {
    if($(window).width() <= 1024) ajustement = 0
    $.fn.BLK_smoothScroll.destroy()
    $('html, body').animate({
      scrollTop: ( destination != '' ? $(destination).offset().top + ajustement : 0 )
    }, duration, 'easeInOutExpo', function() {
      $.fn.BLK_smoothScroll()
    })
  })
}


// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (ex) {}
  }
}


// Ajout du parallax sur l'accueil
/*export function parallax() {
  $.fn.BLK_parallax({
    elements: [
      { element: '#svgTeamStroke, #svgTeamFull', intensityY: -0.05 },
      { element: '#svgTestimonialsStroke, #svgTestimonialsFull', intensityY: -0.05 },
      { element: '#homeBanner .image', type: 'background', intensityY: -0.3, breakpoint: 1024 },
    ]
  })
}*/


// Permet de rediriger l'utilisateur sur les nouvelles filtrées selon son choix
export function newsFilter() {
  $('#newsSelectFilter').change(function(){
    Barba.Pjax.goTo($(this).val())
  })
}


// Permet d'animer les éléments loader dynamiquement
export function loadAnimation(element) {
  setTimeout(function() { $(element + ' [data-scrollfire]').attr('data-scrollfire', 'fire') },200)
}


// Permet de partager les pages
export function shareModal() {
  // Le plugin de share this est PD donc il faut un customRefresh qui l'empêche de s'exécuter deux fois
  if (__sharethis__.customRefresh === true) {
    __sharethis__.init(__sharethis__.config)
  } else {
    __sharethis__.customRefresh = true
  }

  $('.btnShare').click(function() {
    var pageurl = $(this).data('url')
    $('#facebookShareBtn').data('url', pageurl)
    $('#facebookShareBtn').attr('data-url',pageurl)
    $('#twitterShareBtn').data('url', pageurl)
    $('#twitterShareBtn').attr('data-url', pageurl)
    $('#copyShareBtn').data('url', pageurl)
    $('#copyShareBtn').attr('data-url', pageurl)
    $('#emailShareBtn').attr('href', 'mailto:?Subject=' + encodeURIComponent('CJE de D’Autray-Joliette') + '&body=' + encodeURIComponent(pageurl))

    return false
  })

  $('#copyShareBtn').click( function() {
    var link = $(this).data('url')
    copyTextToClipboard(link)
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })

  // disable custom share default behavior.
  $('.st-custom-button'). click ( function() {
    return false
  })
}


// Permet de copier un text dans le presse-papier
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    var textArea = document.createElement('textarea')
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try { var successful = document.execCommand('copy') } catch (err) {}

    document.body.removeChild(textArea)
    $(document).scrollTop(0)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}


// Permet de soumettre la recherche seulement si le menu est ouvert
export function searchButton() {
  $('#submitSearch').on('click', function() {
    if($('html').hasClass('showOverlayMenu')) $('#formSearch').submit()
  })
}

// Détermine si le navigateur utilisé est IE et ajoute une classe sur l'élément HTML
export function isIE() {
  if (window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./))
    $('html').addClass('isIE')
}

export function coordsHash() {
  if (window.location.hash) {
    $('html, body').animate({
      scrollTop: ( $('#coordsBlock').offset().top - 50)
    }, 300, 'easeInOutExpo', function() {
      $.fn.BLK_smoothScroll()
    })
  }
}